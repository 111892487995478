import React, { useState } from "react";
import "./signUp.scss";

import Input from "../input/input";
import Button from "../button/button";
import { postRequest } from "../../services/api.service";
import { setLocalStorage } from "../../services/utli.services";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/apiEndpoint";
import ValidationMessage from "../validationMessage/validationMessage";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import ToastNotification from "../toast/toast";

const SignUp = (props) => {
  const navigate = useNavigate();
  const [signUpForm, setSignUpForm] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // const [signUpData, setSignUpData] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (val, keyName) => {
    setSignUpForm((prevForm) => ({
      ...prevForm,
      [keyName]: val,
    }));

    if (formErrors[keyName]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [keyName]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!signUpForm.userName) {
      errors.userName = "Username is required.";
    }
    if (!signUpForm.email) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(signUpForm.email)) {
      errors.email = "Email address is invalid.";
    }
    if (!signUpForm.password) {
      errors.password = "Password is required.";
    }
    if (!signUpForm.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    } else if (signUpForm.password !== signUpForm.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }
    return errors;
  };

  const singUp = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    props.setLoading(true);
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const data = {
      StudentId: 0,
      Username: signUpForm.userName,
      EmailID: signUpForm.email,
      Password: signUpForm.password,
      Date: formattedDate,
    };
    try {
      const response = await postRequest(API.signUp, data);
      const jsonMatch = response.match(/{.*}/);

      if (jsonMatch) {
        const data = JSON.parse(jsonMatch[0]);
        // setSignUpData(Data);
        if (data) {
          if (data.Status === "ExistsBoth") {
            setToast({
              open: true,
              message: "Username and email already exist",
              severity: "error",
            });
          } else if (data.Status === "ExistsUsername") {
            setToast({
              open: true,
              message: "Username already exist",
              severity: "error",
            });
          } else if (data.Status === "ExistsEmail") {
            setToast({
              open: true,
              message: "Email already exist",
              severity: "error",
            });
          }

          if (data.Status === "Success") {
            login();
            setToast({
              open: true,
              message: "Successfully Created",
              severity: "success",
            });
          }
        }
      } else {
        console.error("Failed to extract JSON from response");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      props.setLoading(false); // Reset loading state after the request is complete
    }
  };

  const login = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const data = {
      EmailID: signUpForm.userName,
      Password: signUpForm.password,
      Date: formattedDate,
    };
    try {
      const response = await postRequest(API.signIn, data);
      const jsonMatch = response.match(/{.*}/);
      const Data = JSON.parse(jsonMatch[0]);
      setLocalStorage("loginUserData", Data);
      navigate("/home/quiz");
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div className="signup-component">
      <div className="signup-label">
        <span>Sign up</span>
      </div>
      <div className="signup-form">
        <Input
          type="string"
          name="userName"
          placeholder="Username"
          value={signUpForm.userName}
          onChange={(val) => handleInputChange(val, "userName")}
          startAdornmentIcon={<PermIdentityOutlinedIcon />}
        />
        {formErrors.userName && (
          <ValidationMessage error={formErrors.userName} />
        )}

        <Input
          type="string"
          name="email"
          placeholder="Email"
          value={signUpForm.email}
          onChange={(val) => handleInputChange(val, "email")}
          startAdornmentIcon={<MailOutlineOutlinedIcon />}
        />
        {formErrors.email && <ValidationMessage error={formErrors.email} />}

        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={signUpForm.password}
          onChange={(val) => handleInputChange(val, "password")}
          startAdornmentIcon={<LockOutlinedIcon />}
        />
        {formErrors.password && (
          <ValidationMessage error={formErrors.password} />
        )}

        <Input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={signUpForm.confirmPassword}
          onChange={(val) => handleInputChange(val, "confirmPassword")}
          startAdornmentIcon={<LockOutlinedIcon />}
        />
        {formErrors.confirmPassword && (
          <ValidationMessage error={formErrors.confirmPassword} />
        )}

        <Button
          className="sing-up-button"
          buttonType={"orange"}
          onClick={singUp}
        >
          Sign up
        </Button>

        <p className="already-button">
          Already have an account?
          <span className="signin-label" onClick={props.SignInButton}>
            Sign in
          </span>
        </p>
      </div>
      <ToastNotification
        open={toast.open}
        onClose={() => setToast((prev) => ({ ...prev, open: false }))}
        message={toast.message}
        severity={toast.severity}
      />
    </div>
  );
};

export default SignUp;
