import React, { useEffect, useState } from "react";
import "../../style/style.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { AppSidebar } from "./index";
import {
  removeLocalStorage,
  getLocalStorage,
} from "../../services/utli.services";
import { useNavigate } from "react-router-dom";
import Model from "../model/model";
import { ListItemIcon, Stack, Typography } from "@mui/material";
import Input from "../input/input";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import ValidationMessage from "../validationMessage/validationMessage";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import StudentInfoModel from "../studentInfoModel/studentInfoModel";
import { postRequest } from "../../services/api.service";
import { API } from "../../config/apiEndpoint";
import ToastNotification from "../toast/toast";
import { trophiesLists } from "../../utils/constants";
import logo from "../../assets/Logo-White.png";
import tokenIcon from "../../assets/tokenIcon.svg";
import CustomLoader from "../customLoader/CustomLoader";
const AppHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [editProfile, setEditProfile] = useState({
    userName: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loginData, setLoginData] = useState("");
  const [viewAllStudentData, setViewAllStudentData] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [earnedTrophies, setEarnedTrophies] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLoginData = () => {
      const userData = getLocalStorage("loginUserData");
      if (userData) {
        setLoginData(userData);
      }
    };

    fetchLoginData();
    const intervalId = setInterval(fetchLoginData, 3000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);

  const validateForm = () => {
    let errors = {};
    if (!editProfile.userName) errors.userName = "Username is required.";
    if (!editProfile.email) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(editProfile.email)) {
      errors.email = "Email address is invalid.";
    }
    if (!editProfile.currentPassword) {
      errors.currentPassword = "Current password is required.";
    }
    if (loginData.PasswordHash !== editProfile.currentPassword) {
      errors.currentPassword = "Current password dose not match.";
    }
    if (editProfile.newPassword !== editProfile.confirmPassword)
      errors.confirmPassword = "Passwords do not match.";
    return errors;
  };

  const handleEditProfileData = (keyName, value) => {
    setEditProfile({
      ...editProfile,
      [keyName]: value,
    });

    if (formErrors[keyName]) {
      setFormErrors({
        ...formErrors,
        [keyName]: "",
      });
    }
  };

  const handleSubmitProfile = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setOpen(false);
    setAnchorEl(null);
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const baseData = {
      action: "EditStudentData",
      StudentID: loginData.StudentId,
      Username: editProfile.userName,
      EmailID: editProfile.email,
      Password: editProfile.confirmPassword.length
        ? editProfile.confirmPassword
        : editProfile.currentPassword,
      StudentClass: loginData.Class,
      StudentLevel: loginData.Level,
      UpdateDate: formattedDate,
    };

    try {
      if (editProfile.confirmPassword.length) {
        await postRequest(API.AdminCsvData, baseData);
        handleClose();
        setToast({
          open: true,
          message:
            loginData.UserType === 0
              ? "Student updated successfully"
              : loginData.UserType === 2
              ? "Admin updated successfully"
              : "Teacher updated successfully",
          severity: "success",
        });
        setEditProfile({
          ...editProfile,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setTimeout(() => {
          handleLogOut();
        }, 1000);
      }
    } catch (error) {
      setToast({
        open: true,
        message: "Failed to submit data.",
        severity: "error",
      });
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEditProfile = () => {
    setOpen(true);
    setAnchorEl(null);
    setEditProfile({
      ...editProfile,
      userName: loginData.Username,
      email: loginData.Email,
    });
  };

  const getStudentTrophies = async (id) => {
    setLoading(true);
    const data = {
      StudentID: id,
      Is_Get: "True",
    };
    try {
      const response = await postRequest(API.StudentTrophies, data);
      const jsonMatch = response.match(/{.*}/);
      const studentTrophiesData = JSON.parse(jsonMatch[0]);
      setLoading(false);
      setEarnedTrophies(studentTrophiesData.trophies);
    } catch (error) {
      setLoading(false);
      console.error("Error getting delete student:", error);
    }
  };

  const handleStudentProfile = () => {
    setViewAllStudentData(true);
    getStudentTrophies(loginData?.StudentId);
  };

  const onClickLogOut = () => {
    setOpenLogout(true);
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    removeLocalStorage("loginUserData");
    removeLocalStorage("logStrick");
    removeLocalStorage("selectedTabIndex");
    navigate("/login");
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        marginTop: "30px",
      }}
    >
      {loginData?.UserType === 0 ? (
        <MenuItem onClick={() => handleStudentProfile()}>
          <ListItemIcon>
            <PersonRoundedIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
      ) : (
        <MenuItem onClick={() => handleEditProfile()}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Profile
        </MenuItem>
      )}
      <MenuItem onClick={() => onClickLogOut()}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Log Out
      </MenuItem>
    </Menu>
  );

  const filteredTrophies = trophiesLists?.filter((trophy) =>
    earnedTrophies?.some((earned) => earned.TrophyId === trophy.id)
  );

  return (
    <Box sx={{ display: "flex" }}>
      {loading && <CustomLoader />}
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          {loginData?.UserType !== 0 && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(!drawerOpen)}
              sx={{ display: { xs: "block", sm: "block", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img
            className="logo"
            style={{ height: "64px" }}
            src={logo}
            alt="logo"
          />
          <Box sx={{ flexGrow: 1 }} />
          {loginData?.UserType === 0 && (
            <div className="token">
              <img
                className="token-icon"
                style={{ height: "30px" }}
                src={tokenIcon}
                alt="token"
              />
              <p className="token-label">{loginData?.QuizTokenCount} Tokens</p>
            </div>
          )}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      {loginData?.UserType !== 0 && (
        <AppSidebar
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
          setDrawerOpen={setDrawerOpen}
        />
      )}

      {open && (
        <Model
          open={open}
          handleClose={handleClose}
          title="Edit Profile"
          handleButton={handleSubmitProfile}
          btnLabel={"Save changes"}
        >
          <Stack gap="1rem" style={{ textAlign: "center" }}>
            <Input
              type="string"
              placeholder={"Username"}
              disabled={loginData?.UserType === 0 ? true : false}
              onChange={(val) => handleEditProfileData("userName", val)}
              value={editProfile.userName}
              startAdornmentIcon={<PermIdentityOutlinedIcon fontSize="small" />}
            />
            {formErrors.userName && (
              <ValidationMessage error={formErrors.userName} />
            )}

            <Input
              type="string"
              placeholder={"Email"}
              disabled={loginData?.UserType === 0 ? true : false}
              onChange={(val) => handleEditProfileData("email", val)}
              value={editProfile.email}
              startAdornmentIcon={<MailOutlineOutlinedIcon fontSize="small" />}
            />
            {formErrors.email && <ValidationMessage error={formErrors.email} />}

            <Input
              type="password"
              placeholder={"Current Password"}
              onChange={(val) => handleEditProfileData("currentPassword", val)}
              value={editProfile.currentPassword}
              startAdornmentIcon={<LockOutlinedIcon fontSize="small" />}
            />
            {formErrors.currentPassword && (
              <ValidationMessage error={formErrors.currentPassword} />
            )}

            <Input
              type="password"
              placeholder={"New Password"}
              onChange={(val) => handleEditProfileData("newPassword", val)}
              value={editProfile.newPassword}
              startAdornmentIcon={<LockOutlinedIcon fontSize="small" />}
            />

            <Input
              type="password"
              placeholder={"Confirm Password"}
              onChange={(val) => handleEditProfileData("confirmPassword", val)}
              value={editProfile.confirmPassword}
              startAdornmentIcon={<LockOutlinedIcon fontSize="small" />}
            />
            {formErrors.confirmPassword && (
              <ValidationMessage error={formErrors.confirmPassword} />
            )}

            <Typography sx={{ color: "#9FABB5", fontSize: "12px" }}>
              You can change your details at any time.
            </Typography>
          </Stack>
        </Model>
      )}
      {renderMenu}

      {openLogout && (
        <Model
          open={openLogout}
          handleClose={() => setOpenLogout(false)}
          handleButton={() => handleLogOut()}
          title="Logout"
          btnLabel={"Yes"}
          nobutton={"No"}
          confirmationString={"Are you sure you want to logout ?"}
        />
      )}

      {viewAllStudentData && (
        <StudentInfoModel
          open={viewAllStudentData}
          onClose={() => setViewAllStudentData(false)}
          data={loginData}
          editProfile={() => handleEditProfile()}
          isStudent={loginData?.UserType === 0}
          trophies={filteredTrophies}
        />
      )}

      <ToastNotification
        open={toast.open}
        onClose={() => setToast((prev) => ({ ...prev, open: false }))}
        message={toast.message}
        severity={toast.severity}
      />
    </Box>
  );
};

export default AppHeader;
