import { Stack } from "@mui/material";
import React from "react";
import Loader from "react-js-loader";

function CustomLoader() {
  return (
    <Stack
      sx={{
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(10px)",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Loader
        type="box-rectangular"
        bgColor="#2fa0e2"
        color="#000000"
        fontWeight="400"
        title={"Loading..."}
        size={100}
      />
    </Stack>
  );
}

export default CustomLoader;
