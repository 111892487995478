import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./signIn.scss";

import Input from "../input/input";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "../button/button";
import ValidationMessage from "../validationMessage/validationMessage"; // Import ValidationMessage component

import { postRequest } from "../../services/api.service";
import { setLocalStorage } from "../../services/utli.services";
import { API } from "../../config/apiEndpoint";
import ToastNotification from "../toast/toast";

const SignIn = (props) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({}); // State for validation errors
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const validateForm = () => {
    let errors = {};
    if (!userName) {
      errors.userName = "Email / Username is required.";
    }
    if (!password) {
      errors.password = "Password is required.";
    }
    return errors;
  };

  const getStudentTrophies = async (id) => {
    const data = {
      StudentID: id,
      Is_Get: "True",
    };
    try {
      await postRequest(API.StudentTrophies, data);
    } catch (error) {
      console.error("Error getting delete student:", error);
    }
  };

  const getStudentLog = async (id, loginStick, currentData) => {
    const data = {
      StudentID: id,
    };
    try {
      const response = await postRequest(API.StudentLog, data);
      const jsonMatch = response.match(/{.*}/);
      const studentLogData = JSON.parse(jsonMatch[0]);

      handleLogStick(currentData, studentLogData.LastLoginTime, loginStick);
    } catch (error) {
      console.error("Error getting student log:", error);
    }
  };

  const handleSingInClick = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    props.setLoading(true); // Start loading
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const data = {
      EmailID: userName,
      Password: password,
      Date: formattedDate,
    };

    try {
      const response = await postRequest(API.signIn, data);
      const jsonMatch = response.match(/{.*}/);
      if (jsonMatch) {
        const parsedData = JSON.parse(jsonMatch[0]);
        setLocalStorage("loginUserData", parsedData);
        setToast({
          open: true,
          message: "Signed in successfully",
          severity: "success",
        });

        if (parsedData?.UserType === 0) {
          await getStudentTrophies(parsedData?.StudentId);
          await getStudentLog(
            parsedData?.StudentId,
            parsedData.LoginDayStrikes,
            formattedDate
          );
          navigate("/home/quiz");
        } else {
          navigate("/home/dashboard");
          await getStudentTrophies(parsedData?.StudentId);
        }
      } else {
        console.error("Failed to extract JSON from response");
        setToast({
          open: true,
          message: "Username or Password Invalid",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setToast({
        open: true,
        message: "An error occurred. Please try again.",
        severity: "error",
      });
    } finally {
      props.setLoading(false); // End loading
    }
    setUserName("");
    setPassword("");
  };

  function extractDate(dateStr) {
    // Extract 'YYYY-MM-DD' part from the date string
    const datePart = dateStr.split(" ")[0];
    return new Date(datePart + "T00:00:00"); // Set time to midnight
  }

  const handleLogStick = (currentDate, lastLogData, loginStick) => {
    const lastLogin = lastLogData;
    const today = currentDate;

    const lastLoginDate = extractDate(lastLogin);
    const todayDate = extractDate(today);

    const timeDifference = todayDate - lastLoginDate;

    let dayDifference = timeDifference / (1000 * 60 * 60 * 24);
    setLocalStorage("dayDifference", dayDifference);

    let logStrick = 0;
    if (dayDifference !== 0) {
      if (dayDifference > 1) {
        logStrick = 1;
      } else {
        logStrick = loginStick ? loginStick + 1 : 1;
      }
    } else {
      logStrick = loginStick === 0 ? 1 : loginStick;
    }
    setLocalStorage("logStrick", logStrick);
  };

  return (
    <div className="signin-component">
      <div className="signin-label">
        <span>Sign in</span>
      </div>
      <div className="signin-form">
        <Input
          id="outlined-basic"
          variant="outlined"
          type="string"
          // label={"Email / Username"}
          placeholder={"Email / Username"}
          value={userName}
          onChange={(val) => {
            setUserName(val);
            if (formErrors.userName) {
              setFormErrors({
                ...formErrors,
                userName: "",
              });
            }
          }}
          startAdornmentIcon={<PermIdentityOutlinedIcon />}
        />
        {formErrors.userName && (
          <ValidationMessage error={formErrors.userName} />
        )}

        <Input
          type="password"
          variant="outlined"
          // label={"Password"}
          placeholder={"Password"}
          value={password}
          onChange={(val) => {
            setPassword(val);
            if (formErrors.password) {
              setFormErrors({
                ...formErrors,
                password: "",
              });
            }
          }}
          startAdornmentIcon={<LockOutlinedIcon />}
        />
        {formErrors.password && (
          <ValidationMessage error={formErrors.password} />
        )}

        <Button
          className="signin-button"
          onClick={handleSingInClick}
          buttonType={"blue"}
        >
          Sign in
        </Button>

        <p className="signup-button" onClick={props.signUpButton}>
          Sign up
        </p>
      </div>
      <ToastNotification
        open={toast.open}
        onClose={() => setToast((prev) => ({ ...prev, open: false }))}
        message={toast.message}
        severity={toast.severity}
      />
    </div>
  );
};

export default SignIn;
