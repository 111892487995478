import React from "react";
import "./button.scss";

import { Button as MuiButton } from "@mui/material";

const Button = (props) => {
  return (
    <>
      {props.buttonType === "blue" && (
        <MuiButton
          variant={props.variant || "contained"}
          block={props.fullWidth}
          size={props.size}
          shape={props.shape}
          color={props.color || "primary"}
          disabled={props.disabled}
          className={props.className + " custom-button-class-blue font-style"}
          onClick={props.onClick}
          style={props.style}
        >
          {props.children}
        </MuiButton>
      )}

      {props.buttonType === "orange" && (
        <MuiButton
          variant={props.variant || "contained"}
          block={props.fullWidth}
          size={props.size}
          shape={props.shape}
          color={props.color || "primary"}
          disabled={props.disabled}
          className={props.className + " custom-button-class-org font-style"}
          onClick={props.onClick}
          style={props.style}
        >
          {props.children}
        </MuiButton>
      )}

      {props.buttonType === "primary-blue" && (
        <MuiButton
          variant={props.variant || "contained"}
          block={props.fullWidth}
          size={props.size}
          shape={props.shape}
          color={props.color || "primary"}
          disabled={props.disabled}
          className={
            props.className + " custom-button-class-blue-primary font-style"
          }
          onClick={props.onClick}
          style={props.style}
        >
          {props.children}
        </MuiButton>
      )}

      {props.buttonType === "red" && (
        <MuiButton
          variant={props.variant || "contained"}
          block={props.fullWidth}
          size={props.size}
          shape={props.shape}
          color={props.color || "primary"}
          disabled={props.disabled}
          className={props.className + " custom-button-class-red font-style"}
          onClick={props.onClick}
          style={props.style}
        >
          {props.children}
        </MuiButton>
      )}
    </>
  );
};

export default Button;
